import {
    Checkbox,
    Field,
    Helper,
    SelectInput,
    Tooltip,
} from 'akeneo-design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import { DateTimeInput } from '../../../../component';
import { useDispatch, useSelector } from '../../store/StoreProvider';
import styled from 'styled-components';
import { SynchronizationScope } from '../../model/SynchronizationScope';
import { CompletenessFilterInput } from './CompletenessFilterInput';
import React from 'react';
import { FilterPimAttribute } from '../../model/FilterPimAttribute';

const ModalContent = styled.div<{ scope: SynchronizationScope }>`
    min-height: ${({ scope }) =>
        scope === SynchronizationScope.Product ? '420px' : '230px'};
`;

const StyledHelper = styled(Helper)`
    font-size: 11px;
`;

const StyledHelperPreExistingProductsOnly = styled(Helper)`
    font-size: 11px;
    margin-top: 5px;
`;

const MarketPricingContainer = styled.div`
    display: flex;
    align-items: center;
`;

const CheckboxLabelContainer = styled.div`
    display: flex;
    font-size: ${({ theme }) => theme.fontSize.default};
    white-space: normal;
`;

const FormTooltipContainer = styled(Tooltip).attrs<{ children: string }>(
    (props) => ({
        iconSize: 14,
        width: 350,
        children: props.children,
    })
)`
    height: 20px;
    width: 20px;
`;
type Props = {
    scope: SynchronizationScope;
    minCompleteness: number | null;
    setMinCompleteness: (value: number) => void;
    filterAttribute: string | null;
    setFilterAttribute: (value: string | null) => void;
    filterPimAttributes: FilterPimAttribute[];
    isPreExistingCatalogMappingConfigured: boolean;
    isProductMediaMappingEmpty: boolean;
    isMetafieldAttributeMappingEmpty: boolean;
    isMetafieldAssociationMappingEmpty: boolean;
    isSecondaryLocalesMappingEmpty: boolean;
    isMarketsPricingMappingEmpty: boolean;
    isPublishingChannelsConfigured: boolean;
};

export const FiltersForm = ({
    scope,
    minCompleteness,
    setMinCompleteness,
    filterAttribute,
    setFilterAttribute,
    filterPimAttributes,
    isPreExistingCatalogMappingConfigured,
    isProductMediaMappingEmpty,
    isMetafieldAttributeMappingEmpty,
    isMetafieldAssociationMappingEmpty,
    isSecondaryLocalesMappingEmpty,
    isMarketsPricingMappingEmpty,
    isPublishingChannelsConfigured,
}: Props) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const filters = useSelector((state) => state.filters);

    const handleMethodChange = (method: string) =>
        dispatch({
            type: 'filters/changed',
            payload: {
                ...filters,
                method: method as 'FULL' | 'DIFFERENTIAL',
            },
        });

    const handleDateTimeChange = (updated: string) =>
        dispatch({
            type: 'filters/changed',
            payload: {
                ...filters,
                updated,
            },
        });

    const handleMediasChange = (medias: boolean) =>
        dispatch({
            type: 'filters/changed',
            payload: {
                ...filters,
                medias,
            },
        });

    const handleMetafieldAttributesChange = (metafields: boolean) =>
        dispatch({
            type: 'filters/changed',
            payload: {
                ...filters,
                metafields,
            },
        });

    const handleMetafieldAssociationsChange = (associations: boolean) =>
        dispatch({
            type: 'filters/changed',
            payload: {
                ...filters,
                associations,
            },
        });

    const handleSecondaryLocalesChange = (secondaryLocales: boolean) =>
        dispatch({
            type: 'filters/changed',
            payload: {
                ...filters,
                secondaryLocales,
            },
        });

    const handleMarketsPricingChange = (marketsPricing: boolean) =>
        dispatch({
            type: 'filters/changed',
            payload: {
                ...filters,
                marketsPricing,
            },
        });

    const handlePreExistingProductsOnlyChange = (
        preExistingProductsOnly: boolean
    ) => {
        dispatch({
            type: 'filters/changed',
            payload: {
                ...filters,
                preExistingProductsOnly,
            },
        });
    };

    const handlePublishingChannelsChange = (publishingChannels: boolean) => {
        dispatch({
            type: 'filters/changed',
            payload: {
                ...filters,
                publishingChannels,
            },
        });
    };

    const handleCompletenessChange = (value: string) => {
        setMinCompleteness(parseInt(value));
    };

    return (
        <ModalContent scope={scope}>
            <Helper level='info'>
                <FormattedMessage
                    id='Zaj5n6'
                    defaultMessage='If you choose the full import, the whole catalog will be imported. For the first import, please use full mode only.'
                />
            </Helper>
            <br />
            <Field
                label={intl.formatMessage({
                    id: 'MMGQKE',
                    defaultMessage: 'Import process (required)',
                })}
            >
                <SelectInput
                    value={filters.method}
                    onChange={handleMethodChange}
                    clearable={false}
                    emptyResultLabel=''
                    openLabel=''
                >
                    <SelectInput.Option value='DIFFERENTIAL'>
                        <FormattedMessage id='kDNmhM' defaultMessage='Delta' />
                    </SelectInput.Option>

                    <SelectInput.Option value='FULL'>
                        <FormattedMessage id='NYnkyd' defaultMessage='Full' />
                    </SelectInput.Option>
                </SelectInput>
            </Field>
            {filters.method === 'DIFFERENTIAL' && (
                <>
                    <br />
                    <div>
                        <Field
                            label={intl.formatMessage({
                                id: 'UL9OEs',
                                defaultMessage: 'Select the reference date',
                            })}
                        >
                            <DateTimeInput
                                dateTime={filters.updated}
                                onChange={handleDateTimeChange}
                            />
                        </Field>
                    </div>
                </>
            )}
            {scope === SynchronizationScope.Product && (
                <>
                    <br />
                    <Field
                        label={intl.formatMessage({
                            id: 'aJbRki',
                            defaultMessage: 'Completeness',
                        })}
                    >
                        <CompletenessFilterInput
                            onChange={handleCompletenessChange}
                            minCompleteness={minCompleteness}
                        />
                        <StyledHelper level='info' inline>
                            <FormattedMessage
                                id='IExcXx'
                                defaultMessage='Apply this filter to import or update the products by their completeness.'
                            />
                        </StyledHelper>
                    </Field>
                </>
            )}
            <br />
            <Field
                label={intl.formatMessage({
                    id: '+fhF3Y',
                    defaultMessage: 'Filter attribute',
                })}
            >
                <SelectInput
                    value={filterAttribute}
                    onChange={setFilterAttribute}
                    clearable={true}
                    emptyResultLabel=''
                    openLabel=''
                >
                    {filterPimAttributes.map((option) => (
                        <SelectInput.Option
                            key={option.code}
                            value={option.code}
                        >
                            {option.label}
                        </SelectInput.Option>
                    ))}
                </SelectInput>
                <StyledHelper level='info' inline>
                    <FormattedMessage
                        id='dodKbD'
                        defaultMessage='Please select a PIM attribute that will be used to filter the products to be synchronized.'
                    />
                </StyledHelper>
            </Field>
            <br />
            {!isProductMediaMappingEmpty && (
                <>
                    <br />
                    <Checkbox
                        checked={filters.medias}
                        onChange={handleMediasChange}
                    >
                        <CheckboxLabelContainer>
                            <FormattedMessage
                                id='6KdOZT'
                                defaultMessage='Import media'
                            />
                        </CheckboxLabelContainer>
                    </Checkbox>
                    <br />
                </>
            )}
            {!isMetafieldAttributeMappingEmpty && (
                <>
                    <Checkbox
                        checked={filters.metafields}
                        onChange={handleMetafieldAttributesChange}
                    >
                        <CheckboxLabelContainer>
                            <FormattedMessage
                                id='BI5+0N'
                                defaultMessage='Import metafields - PIM attributes'
                            />
                            <FormTooltipContainer
                                children={intl.formatMessage({
                                    id: '/c/4Xh',
                                    defaultMessage:
                                        "The app won't synchronize PIM attributes if turned off.",
                                })}
                            />
                        </CheckboxLabelContainer>
                    </Checkbox>
                    <br />
                </>
            )}
            {!isMetafieldAssociationMappingEmpty && (
                <>
                    <Checkbox
                        checked={filters.associations}
                        onChange={handleMetafieldAssociationsChange}
                    >
                        <CheckboxLabelContainer>
                            <FormattedMessage
                                id='v59v/u'
                                defaultMessage='Import metafields - PIM associations'
                            />
                            <FormTooltipContainer
                                children={intl.formatMessage({
                                    id: 'rAVYgA',
                                    defaultMessage:
                                        "The app won't synchronize PIM associations if turned off.",
                                })}
                            />
                        </CheckboxLabelContainer>
                    </Checkbox>
                    <br />
                </>
            )}
            {!isSecondaryLocalesMappingEmpty && (
                <>
                    <Checkbox
                        checked={filters.secondaryLocales}
                        onChange={handleSecondaryLocalesChange}
                    >
                        <CheckboxLabelContainer>
                            <FormattedMessage
                                id='tfKB+k'
                                defaultMessage='Import secondary locales'
                            />
                        </CheckboxLabelContainer>
                    </Checkbox>
                    <br />
                </>
            )}
            {!isMarketsPricingMappingEmpty && (
                <>
                    <MarketPricingContainer>
                        <Checkbox
                            checked={filters.marketsPricing}
                            onChange={handleMarketsPricingChange}
                        >
                            <CheckboxLabelContainer>
                                <FormattedMessage
                                    id='/9jMg3'
                                    defaultMessage='Import markets pricing'
                                />
                            </CheckboxLabelContainer>
                        </Checkbox>
                        <FormTooltipContainer
                            children={intl.formatMessage({
                                id: '+D4RTe',
                                defaultMessage:
                                    "The app won't synchronize markets pricing if turned off.",
                            })}
                        />
                    </MarketPricingContainer>
                    <br />
                </>
            )}

            {isPreExistingCatalogMappingConfigured && (
                <>
                    <Checkbox
                        checked={filters.preExistingProductsOnly}
                        onChange={handlePreExistingProductsOnlyChange}
                    >
                        <CheckboxLabelContainer>
                            <FormattedMessage
                                defaultMessage='Import pre-existing products only'
                                id='Gm34kQ'
                            />
                        </CheckboxLabelContainer>
                    </Checkbox>
                    <StyledHelperPreExistingProductsOnly level='info' inline>
                        <FormattedMessage
                            defaultMessage='Apply this filter to synchronize only PIM products with pre-existing Shopify products.'
                            id='h5WGD2'
                        />
                    </StyledHelperPreExistingProductsOnly>
                    <br />
                </>
            )}
            {isPublishingChannelsConfigured && (
                <>
                    <Checkbox
                        checked={filters.publishingChannels}
                        onChange={handlePublishingChannelsChange}
                    >
                        <CheckboxLabelContainer>
                            <FormattedMessage
                                defaultMessage='Publish Products'
                                id='oz8w8d'
                            />
                        </CheckboxLabelContainer>
                    </Checkbox>
                    <StyledHelperPreExistingProductsOnly level='info' inline>
                        <FormattedMessage
                            defaultMessage="The app won't publish products if turned off"
                            id='eQ52YE'
                        />
                    </StyledHelperPreExistingProductsOnly>
                    <br />
                </>
            )}
        </ModalContent>
    );
};
